html {
  box-sizing: border-box;
  @include firefox-scroll;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: $black;
  text-decoration: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    text-decoration: none;
  }
}

p {
  margin-bottom: 0;
}
