.header {
  background-color: $white;
  box-shadow: 0px 5px 5px rgba($black, 0.13);
  position: sticky;
  top: 0;
  z-index: 3;

  @include media-breakpoint-down(lg) {
    box-shadow: none;
    top: 0;

    &::before {
      content: "";
      position: absolute;
      left: auto;
      top: 0;
      width: 100%;
      height: 60px;
      z-index: 1;
      box-shadow: 0px 5px 5px rgba($black, 0.13);
    }
  }

  .container {
    max-width: 1300px;
  }

  .navbar-light {
    .navbar-brand {
      img {
        @include media-breakpoint-down(lg) {
          max-width: 120px;
        }
      }

      @include media-breakpoint-down(lg) {
        z-index: 1;
      }
    }

    .navbar-toggler {
      @include media-breakpoint-down(lg) {
        border: 0;
        z-index: 1;
        height: 18px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        position: relative;

        span {
          display: flex;
          height: 2px;
          width: 100%;
          background-color: $dark;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }

        &:not(.collapsed) {
          span {
            &:nth-child(1) {
              -webkit-transform: translateY(8px) rotate(45deg);
              -ms-transform: translateY(8px) rotate(45deg);
              -o-transform: translateY(8px) rotate(45deg);
              transform: translateY(8px) rotate(45deg);
            }

            &:nth-child(2) {
              opacity: 0;
            }

            &:nth-child(3) {
              -webkit-transform: translateY(-8px) rotate(-45deg);
              -ms-transform: translateY(-8px) rotate(-45deg);
              -o-transform: translateY(-8px) rotate(-45deg);
              transform: translateY(-8px) rotate(-45deg);
            }
          }
        }
      }
    }

    .navbar-nav {
      @include media-breakpoint-down(lg) {
        padding: 30px;
      }
      .nav-item {
        .nav-link {
          padding-top: 5px;
          padding-bottom: 5px;

          @include media-breakpoint-between(lg, xl) {
            font-size: 14px;
          }

          @include media-breakpoint-down(lg) {
            display: inline-block;
            color: $primary;
            font-weight: $font-weight-medium;
            padding-right: 20px;
          }

          @include media-breakpoint-up(lg) {
            padding-top: 25px;
            padding-bottom: 25px;
          }

          &.active {
            font-weight: $font-weight-medium;

            &:hover {
              font-weight: $font-weight-medium;
            }
          }

          &:hover {
            font-weight: $font-weight-normal;
          }

          &.dropdown-toggle {
            &.show {
              color: $primary;
              font-weight: $font-weight-normal;

              &.active {
                font-weight: $font-weight-medium;

                &:hover {
                  font-weight: $font-weight-medium;
                }
              }
            }
          }
        }

        &:last-child {
          .nav-link {
            @include media-breakpoint-up(lg) {
              padding-right: 0;
            }
          }
        }

        .dropdown-menu {
          @include media-breakpoint-down(lg) {
            border: 0;
          }

          @include media-breakpoint-up(lg) {
            margin-left: $nav-link-padding-y;
          }

          li {
            .dropdown-item {
              @include media-breakpoint-down(lg) {
                padding: 5px 20px 5px 30px;
                border: 0;
              }
            }
          }
        }
      }
    }
  }
}

/* INNER HEADER */

.header-inner {
  background-color: $white;
  position: sticky;
  top: 75px;
  z-index: 2;
  border-bottom: 1px solid $grey-e7;

  @include media-breakpoint-down(lg) {
    display: none;
  }

  .nav-scroller {
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    .nav {
      .nav-link {
        padding-left: 24px;
        padding-right: 24px;

        &.active {
          background-color: $primary;
          color: $white;
          font-weight: $font-weight-medium;
        }

        &:not(.active) {
          &:hover {
            color: $primary;
            background-color: rgba($primary, 0.05);
          }
        }
      }
    }
  }
}
