// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  // src: local(""), url("../fonts/poppins-v20-latin-300.woff2") format("woff2"),
  src: url("../fonts/poppins-v20-latin-300.woff2") format("woff2"),
    url("../fonts/poppins-v20-latin-300.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/poppins-v20-latin-regular.woff2") format("woff2"),
    url("../fonts/poppins-v20-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/poppins-v20-latin-500.woff2") format("woff2"),
    url("../fonts/poppins-v20-latin-500.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/poppins-v20-latin-600.woff2") format("woff2"),
    url("../fonts/poppins-v20-latin-600.woff") format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/poppins-v20-latin-700.woff2") format("woff2"),
    url("../fonts/poppins-v20-latin-700.woff") format("woff");
}

body {
  background-color: $white;
  color: $dark;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  line-height: $headings-line-height;
  overflow-x: hidden;

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
}
