.header-strip {
  position: sticky;
  top: 0;
  z-index: 2;

  p {
    color: $dark-5d;
    font-size: 14px;

    a {
      font-size: 12px;
    }
  }
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
