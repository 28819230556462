.section-introduction {
  @include background(none, $position: top left, $size: auto);
}

.introduction-content {
  p {
    font-size: 20px;
    line-height: 36px;

    @include media-breakpoint-down(lg) {
      font-size: 18px;
      line-height: 32px;
    }
  }
}

.section-intro-about {
  .home-intro-content {
    max-width: 550px;
    padding: 150px 0;

    @include media-breakpoint-down(lg) {
      padding: 40px 0 20px;
    }
  }

  .image-right-full {
    width: calc(50% - 45px);
  }
}

.section-about-achievements {
  @include background(none, $position: bottom right, $size: auto);

  .container {
    @include media-breakpoint-up(xl) {
      max-width: 1200px;
    }
  }

  .col-lg {
    border-left: 1px solid $white;
  }

  .about-achievements-timeline {
    @include media-breakpoint-down(lg) {
      padding-left: 12px;
    }

    h5 {
      font-size: 48px;
      line-height: 40px;

      @include media-breakpoint-down(lg) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .card {
      max-width: 186px;

      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }

      p {
        font-size: 12px;
      }

      &::before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 10px 0;
        border-color: transparent $white transparent transparent;
        left: -10px;
        position: absolute;
        top: 12px;
      }

      & + .card {
        margin-top: 16px;
      }
    }
  }
}

.section-our-team-banner {
  @include media-breakpoint-up(lg) {
    // background-size: contain;
    // background-position: 0 56px;
    background-attachment: initial;
  }
}

.section-team {
  .container {
    @include media-breakpoint-up(xl) {
      max-width: 1200px;
    }
  }
}

.section-advisors {
  @include background(none, $position: top left, $size: auto);
}

.section-principles-anti {
  .principles-anti-content {
    max-width: 560px;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
  }

  .image-right-full {
    width: calc(50% - 65px);
  }
}

.section-principles-risk {
  .principles-risk-content {
    max-width: 615px;
    margin-left: auto;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
  }

  .image-right-full {
    width: calc(41.66667% - 10px);
  }
}

.section-principles-mission {
  .principles-mission-content {
    max-width: 615px;
    margin-left: auto;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
  }
}

.section-perk {
  position: relative;

  .card-perk {
    .card-perk-image {
      height: 132px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;

      @include media-breakpoint-down(lg) {
        margin-bottom: 30px;
      }
    }
  }

  &::before {
    @include afterbefore($backgroundColor: $primary, $opacity: 0.76);
  }
}

.section-resource-sustainable {
  .resource-sustainable-content {
    max-width: 560px;
  }

  .image-right-full {
    width: 41.66667%;
  }
}

.card-portfolio {
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.portfolio-logo {
  @include media-breakpoint-up(xl) {
    margin-left: -100px;
  }

  @include media-breakpoint-up(lg) {
    margin-left: -50px;
  }
}

.section-contact-info {
  .heading-03 {
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }
}

.about-location {
  position: relative;

  @include media-breakpoint-down(sm) {
    & > img {
      height: 350px;
      object-fit: cover;
    }
  }

  .about-location-list {
    .about-location-list-item {
      position: absolute;
      display: none;

      &:nth-child(1) {
        top: 19.9%;
        left: 49.1%;

        @include media-breakpoint-down(sm) {
          top: 17.5%;
          left: 47.1%;
        }
      }

      &:nth-child(2) {
        top: 22.2%;
        left: 48%;

        @include media-breakpoint-down(sm) {
          top: 13%;
          left: 49.3%;
        }
      }

      &:nth-child(3) {
        top: 50.1%;
        left: 48.6%;

        @include media-breakpoint-down(sm) {
          top: 41%;
          left: 51.6%;
        }
      }

      &:nth-child(4) {
        top: 55.6%;
        left: 59.2%;

        @include media-breakpoint-down(sm) {
          top: 52.6%;
          left: 17.2%;
        }
      }

      .about-location-list-indicator {
        height: 12px;
        width: 12px;
        border-radius: 100%;
        display: inline-block;
        vertical-align: middle;
      }

      .about-location-box {
        max-width: 185px;
        padding: 15px;
        box-shadow: 0px 4px 6px rgba($black, 0.141253);
        background-color: $white;
        display: inline-block;
        vertical-align: top;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 16px;
          left: 0;
          width: 0;
          height: 0;
          box-shadow: -2px 2px 1px -1px rgba($black, 0.141253);
          border: 8px solid $black;
          border-color: transparent transparent $white $white;
          transform-origin: 0 0;
          transform: rotate(45deg);
        }

        h6 {
          color: $primary;
          font-size: 16px;
          font-weight: $font-weight-semibold;
          text-transform: capitalize;
        }

        span {
          color: $black;
          font-size: 12px;
          line-height: 18px;
          display: block;
          margin-top: 5px;
        }
      }
    }
  }
}

.parallax {
  background-attachment: fixed;
}

.section-climate-action {
  .principles-anti-content {
    max-width: 560px;
  }

  .image-right-full {
    width: calc(50% - 65px);

    @include media-breakpoint-down(lg) {
      padding-bottom: 100%;
    }
  }
}

.section-sdg {
  padding: 35px 0 105px;

  .sdg-row {
    .col-lg-7 {
      @include media-breakpoint-up(lg) {
        width: calc(100% - 375px);
      }
    }

    .col-lg-5 {
      @include media-breakpoint-up(lg) {
        width: 375px;
      }

      .sdg-row-body {
        @include media-breakpoint-up(lg) {
          border-left: 0;
        }
      }
    }

    .sdg-row-header {
      height: 100%;
      @include media-breakpoint-down(lg) {
        .heading-03 {
          font-size: 18px;
        }
      }
    }

    .sdg-row-body {
      border: 1px solid $grey-c2;
      border-top: 0;
      height: 100%;

      @include media-breakpoint-down(lg) {
        ul {
          font-size: 16px;
        }
      }

      .sdg-row-image {
        img {
          max-width: calc(33.33% - 7px);
          display: block;
        }
      }
    }
  }
}

.section-responsive-tab {
  .responsive-tabs {
    counter-reset: counterIncrement;

    .nav-item {
      position: relative;

      .nav-link {
        &.active,
        &:hover {
          &::after,
          &::before {
            opacity: 1;
            bottom: -120px;
          }
        }

        &::after {
          counter-increment: counterIncrement;
          content: counter(counterIncrement);
          position: absolute;
          left: -45px;
          right: 0;
          bottom: -90px;
          margin: 0 auto;
          height: 72px;
          width: 72px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          color: $primary;
          opacity: 0;
          -moz-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          -webkit-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
        }

        &::before {
          content: "";
          position: absolute;
          left: -45px;
          right: 0;
          bottom: -90px;
          margin: 0 auto;
          height: 72px;
          width: 72px;
          background-color: $white;
          transform: rotate(45deg);
          opacity: 0;
          -moz-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          -webkit-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
        }
      }

      &:last-child {
        .nav-link {
          .responsive-tab-inner {
            img {
              margin-left: 0 !important;
            }
          }
        }
      }
    }

    .nav-link {
      padding: 0;
      border: 0;
      margin-bottom: 0;
      position: relative;
      -moz-transition: all 0.1s ease-in-out;
      -o-transition: all 0.1s ease-in-out;
      -webkit-transition: all 0.1s ease-in-out;
      transition: all 0.1s ease-in-out;

      img {
        -moz-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        -webkit-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
      }

      &.active,
      &:hover {
        img {
          filter: invert(60%) sepia(50%) saturate(57%) brightness(0%) contrast(80%);
        }
      }
    }

    .nav-tabs {
      display: none;
      border-bottom: 0;
    }
  }

  .responsive-tab-inner {
    position: relative;
    text-align: center;

    .responsive-tab-content {
      .text-primary {
        position: absolute;
        top: 40px;
        left: -45px;
        right: 0;
        font-size: 18px;

        @include media-breakpoint-down(lg) {
          top: 70px;
        }
      }

      .text-dark {
        position: absolute;
        top: 50%;
        left: -45px;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        max-width: 145px;
        margin: 0 auto;
        justify-content: center;
        right: 0;
        font-size: 18px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .responsive-tabs {
      .nav-tabs {
        display: flex;
      }
    }

    .tab-content-responsive {
      .responsive-tab-inner {
        & + .collapse {
          display: block;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .tab-content-responsive {
      @include media-breakpoint-down(lg) {
        pointer-events: none;
      }

      .tab-pane {
        display: block !important;
        opacity: 1;

        &:last-child {
          .responsive-tab-inner {
            .responsive-tab-content {
              span {
                left: 0;
              }
            }
          }
        }
      }

      .responsive-tab-inner {
        padding: 15px;
        margin: 0 -50%;

        &[aria-expanded="true"] {
          img {
            filter: invert(60%) sepia(50%) saturate(57%) brightness(0%) contrast(80%);
          }
        }
      }
    }
  }
}
