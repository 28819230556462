.popover {
  box-shadow: 0px 4px 6px rgba($black, 0.14);

  .popover-arrow {
    left: -9px !important;
  }

  .popover-header {
    font-weight: $font-weight-medium;
    line-height: 1;
  }

  .popover-body {
    padding-top: 0;
    line-height: 18px;
  }
}
