.lazyload {
  opacity: 0;
}

.loaded {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.delay-0 {
  -webkit-animation-delay: 0s !important;
  animation-delay: 0s !important;
}

.delay-1 {
  -webkit-animation-delay: 0.2s !important;
  animation-delay: 0.2s !important;
}

.delay-2 {
  -webkit-animation-delay: 0.4s !important;
  animation-delay: 0.4s !important;
}

.delay-3 {
  -webkit-animation-delay: 0.6s !important;
  animation-delay: 0.6s !important;
}

.delay-4 {
  -webkit-animation-delay: 0.8s !important;
  animation-delay: 0.8s !important;
}

.delay-5 {
  -webkit-animation-delay: 1s !important;
  animation-delay: 1s !important;
}

.delay-6 {
  -webkit-animation-delay: 1.2s !important;
  animation-delay: 1.2s !important;
}

.delay-7 {
  -webkit-animation-delay: 1.4s !important;
  animation-delay: 1.4s !important;
}

.delay-8 {
  -webkit-animation-delay: 1.6s !important;
  animation-delay: 1.6s !important;
}

.delay-9 {
  -webkit-animation-delay: 1.8s !important;
  animation-delay: 1.8s !important;
}

.delay-10 {
  -webkit-animation-delay: 2.0s !important;
  animation-delay: 2.0s !important;
}

.delay-11 {
  -webkit-animation-delay: 2.2s !important;
  animation-delay: 2.2s !important;
}

.delay-12 {
  -webkit-animation-delay: 2.4s !important;
  animation-delay: 2.4s !important;
}

.delay-14 {
  -webkit-animation-delay: 2.6s !important;
  animation-delay: 2.6s !important;
}

.delay-15 {
  -webkit-animation-delay: 2.8s !important;
  animation-delay: 2.8s !important;
}

.loaded.grow {
  -webkit-animation-name: slowgrow;
  animation-name: slowgrow;
}

img.loaded.grow {
  -webkit-animation-name: slowgrow-img;
  animation-name: slowgrow-img;
}

.loaded.grow-slow {
  -webkit-animation-name: slowgrow-img;
  animation-name: slowgrow-img;
}

.loaded.shrink {
  -webkit-animation-name: shrink;
  animation-name: shrink;
}

.loaded.slideleft {
  -webkit-animation-name: slideright;
  animation-name: slideright;
}

.loaded.slideright {
  -webkit-animation-name: slideleft;
  animation-name: slideleft;
}

.loaded.fadein {
  -webkit-animation-name: fadein;
  animation-name: fadein;
}

.loaded.fadeout {
  -webkit-animation-name: fadeout;
  animation-name: fadeout;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}

.loaded.dropin {
  -webkit-animation-name: dropin;
  animation-name: dropin;
}

.loaded.dropup {
  -webkit-animation-name: dropup;
  animation-name: dropup;
}

.loaded.dropdown {
  -webkit-animation-name: dropdown;
  animation-name: dropdown;
}

.loaded.dropright {
  -webkit-animation-name: dropright;
  animation-name: dropright;
}


.loaded.introfade {
  -webkit-animation-name: fadein;
  animation-name: fadein;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}


@keyframes slideright {
  from {
    opacity: 0;
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }
  
}

@-webkit-keyframes slideright {
  from {
    opacity: 0;
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }
  
}

@keyframes slideleft {
  from {
    opacity: 0;
    -webkit-transform: translateX(-18px);
    -ms-transform: translateX(-18px);
    transform: translateX(-18px);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }
  
}

@-webkit-keyframes slideleft {
  from {
    opacity: 0;
    -webkit-transform: translateX(-18px);
    -ms-transform: translateX(-18px);
    transform: translateX(-18px);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }
  
}

@keyframes dropin {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  
}

@-webkit-keyframes dropin {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  
}

@keyframes dropup {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  
}

@-webkit-keyframes dropup {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  
}

@keyframes dropdown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  
}

@-webkit-keyframes dropdown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
  
}

@keyframes dropright {
  from {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }
  
}

@-webkit-keyframes dropright {
  from {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }
  
}

@keyframes dropin-center {
  from {
    opacity: 0;
    -webkit-transform: translate(-50%, -75%);
    -ms-transform: translate(-50%, -75%);
    transform: translate(-50%, -75%);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
}

@-webkit-keyframes dropin-center {
  from {
    opacity: 0;
    -webkit-transform: translate(-50%, -75%);
    -ms-transform: translate(-50%, -75%);
    transform: translate(-50%, -75%);
  }
  
  to {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
}

@keyframes slowgrow {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  
  50% {
    opacity: 1;
  }
  
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  
}

@-webkit-keyframes slowgrow {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  
  50% {
    opacity: 1;
  }
  
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  
}

@keyframes slowgrow-img {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.97);
    -ms-transform: scale(0.97);
    transform: scale(0.97);
  }
  
  50% {
    opacity: 1;
  }
  
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  
}

@-webkit-keyframes slowgrow-img {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.97);
    -ms-transform: scale(0.97);
    transform: scale(0.97);
  }
  
  50% {
    opacity: 1;
  }
  
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  
}

@keyframes slowgrow-center {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0.86);
    -ms-transform: translate(-50%, -50%) scale(0.86);
    transform: translate(-50%, -50%) scale(0.86);
  }
  
  50% {
    opacity: 1;
  }
  
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
  
}

@-webkit-keyframes slowgrow-center {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0.86);
    -ms-transform: translate(-50%, -50%) scale(0.86);
    transform: translate(-50%, -50%) scale(0.86);
  }
  
  50% {
    opacity: 1;
  }
  
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
  
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
  
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
  
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  
  to {
    opacity: 0;
  }
  
}

@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  
  to {
    opacity: 0;
  }
  
}


@keyframes bounce {
  0% {
    -ms-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  
  100% {
    -ms-transform: translateY(12px);
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
  
}

@-webkit-keyframes bounce {
  0% {
    -ms-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  
  100% {
    -ms-transform: translateY(12px);
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
  
}


@keyframes shrink {
  from {
    opacity: 0;
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
  }
  
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  
}

@-webkit-keyframes shrink {
  from {
    opacity: 0;
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
  }
  
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  
}

@-webkit-keyframes pulse {
  from {
    opacity: 0.4;
    -ms-transform: scale(0.84);
    -webkit-transform: scale(0.84);
    transform: scale(0.84);
  }
  
  to {
    opacity: 1;
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
}

@keyframes pulse {
  from {
    opacity: 0.4;
    -ms-transform: scale(0.86);
    -webkit-transform: scale(0.86);
    transform: scale(0.86);
  }
  
  to {
    opacity: 1;
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
}

