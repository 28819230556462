.breadcrumb {
  .breadcrumb-item {
    a {
      &:not(.active) {
        color: $primary;
        font-weight: $font-weight-medium;
      }
    }
  }
}
