.accordion {
  .accordion-item {
    .accordion-button {
      box-shadow: none;

      @include media-breakpoint-down(lg) {
        padding: 15px;
      }

      &.collapsed {
        &::after {
          transform: rotate(-90deg);
        }
      }
    }

    .accordion-body {
      padding-top: 0;
      font-size: 16px;

      @include media-breakpoint-down(lg) {
        padding: 15px;
        padding-top: 0;
      }
    }
  }
}
