.section-home-about {
  .home-about-content {
    max-width: 475px;
    padding-top: 110px;
    padding-bottom: 55px;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }
}

.section-home-feature {
  @include background(none, $position: top left, $size: auto);
}

.card-feature {
  &-image {
    height: 146px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 35px 0;
  }
}

.section-home-esg {
  .home-esg-content {
    max-width: 560px;
    margin: 0 auto;
    padding: 210px 0 40px;

    .heading-07 {
      padding-top: 155px;

      @include media-breakpoint-down(lg) {
        padding-top: 40px;
      }
    }

    @include media-breakpoint-down(lg) {
      padding: 40px 0;
    }
  }

  .image-right-full-left {
    width: calc(50% - 60px);
  }
}

.counter-card {
  h4 {
    line-height: 1.2;
    min-height: 135px;

    @include media-breakpoint-down(lg) {
      min-height: 95px;
    }

    .counter-text {
      display: none;
    }

    .timer-comma {
      &::after {
        content: "+";
      }
    }
  }
}

.col-lg-3 {
  &:not(:last-child) {
    .counter-card {
      @include media-breakpoint-down(lg) {
        margin-bottom: 70px;
      }
    }
  }
}

.card-list {
  border: 1px solid $grey-dd;
  padding: 25px 40px;
  min-height: 155px;
  margin-top: -1px;
  @include media-breakpoint-down(lg) {
    padding: 12px;
  }

  .card-list-date {
    font-size: 10px;
  }
}
