.text-capitalize-first {
  &::first-letter {
    text-transform: capitalize;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-icon-animate {
  display: inline-flex;
  align-items: center;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  img {
    transition: transform 0.3s ease;
  }

  &:hover {
    img {
      transform: translate3d(5px, 0, 0);
    }
  }
}

/* LINK CLASS */

.link {
  &-primary {
    &:hover {
      color: $dark !important;
    }
  }

  &-light {
    color: $white !important;

    &:hover {
      color: $dark !important;
    }
  }

  &-dark {
    &:hover {
      color: $white !important;
    }
  }
}

/* COMMON IMAGE BACKGROUND */

.image-right-full {
  @include background("", center);
  height: 100%;
  width: 50%;
  top: 0;
  right: 0;
  position: absolute;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    width: 100% !important;
    position: relative;
    padding-bottom: 56.25%;
  }

  @include media-breakpoint-down(sm) {
    width: calc(100% + 24px) !important;
    margin-left: -12px;
    margin-right: -12px;
  }

  &::after {
    @include afterbefore($backgroundColor: $primary);
  }

  &-left {
    left: 0;
    right: auto;
  }
}
