.file-upload {
  position: relative;
  min-height: 185px;
  text-align: center;

  input {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  p {
    color: $grey-bc;
  }
}
