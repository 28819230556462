.bg-banner {
  @include background("", center);
}

.section-hero {
  overflow: hidden;
  min-height: 810px;
  background-attachment: fixed;
  position: relative;

  & > {
    .h-100 {
      min-height: 810px;
    }
  }

  @include media-breakpoint-down(lg) {
    min-height: 550px;
    background-attachment: initial;

    & > {
      .h-100 {
        min-height: 810px;
      }
    }
  }

  &-sm {
    min-height: 580px;

    & > {
      .h-100 {
        min-height: 580px;
      }
    }

    @include media-breakpoint-down(lg) {
      min-height: 450px;

      & > {
        .h-100 {
          min-height: 580px;
        }
      }
    }
  }

  p {
    max-width: 570px;
  }

  &.banner-random {
    overflow: hidden;
    .bg-grey-f7 {
      opacity: 1;
    }

    @include media-breakpoint-up(lg) {
      height: calc(100vh - 117px);
    }

    @include media-breakpoint-up(xl) {
      height: calc(100vh - 120px);
    }
  }
}
