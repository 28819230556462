.swiper-pagination-bullet {
  background: $white;
  height: 13px;
  width: 13px;
}

.swiper-pagination-dark {
  .swiper-pagination-bullet {
    background: $primary;
  }
}
