.footer {
  position: relative;

  .footer-back-top {
    height: 70px;
    width: 70px;
    border-radius: 70px;
    background-color: $primary;
    filter: drop-shadow(0px 3px 10px rgba($black, 0.5));
    display: block;
    position: absolute;
    right: -70px;
    top: -105px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1280px) {
      right: 0;
    }

    @include media-breakpoint-down(lg) {
      right: 15px;
      top: -80px;
      height: 60px;
      width: 60px;
    }

    img {
      max-width: 25px;
    }
  }

  .footer-top {
    .heading-07 {
      white-space: nowrap;
    }

    ul {
      li {
        a {
          color: $white;
          font-size: 14px;
          font-weight: $font-weight-medium;
          margin-bottom: 5px;
          white-space: nowrap;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .footer-bottom {
    ul {
      li {
        &:not(:last-child) {
          margin-right: 10px;

          a {
            &:after {
              content: "|";
              position: absolute;
              right: -8px;
              top: 50%;
              transform: translateY(-50%);
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }

        a {
          color: $white;
          position: relative;
          font-size: 14px;
        }
      }
    }
  }
}
