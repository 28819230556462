// Variables Color

$primary: #1dbab1;
$secondary: #deeeed;
$dark: #0a2724;
$warning: #fdc268;
$danger: #d50100;
$success: #219653;
$white: #ffffff;
/* EXTRA COLORS */
$grey-bc: #bcbcbc;
$grey-c7: #c7c7c7;
$grey-c2: #c2c4c4;
$grey-dd: #dddddd;
$grey-db: #dbdbdb;
$grey-f0: #f0f0f0;
$grey-f7: #f7f6f6;
$grey-c5: #c5c9c8;
$grey-e7: #e7e7e7;
$dark-5d: #5d6261;
$black: #000000;

$theme-colors: (
  "primary": $primary,
  "warning": $warning,
  "danger": $danger,
  "success": $success,
  "dark": $dark,
  "secondary": $secondary,
);

// Custom Color
$customcolor: purple;

$custom-color: (
  "customcolor": $customcolor,
);

// CUSTOM COLOR AND BACKGROUND CLASS

$colors: (
  "grey-f0": $grey-f0,
  "grey-f7": $grey-f7,
);

@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: $value !important;
  }

  .text-#{$color} {
    color: $value !important;
  }

  .border-#{$color} {
    border-color: $value !important;
  }
}

// Merge Color
$theme-colors: map-merge($theme-colors, $custom-color);

// FONTS

$font-family-sans-serif: "Poppins", sans-serif;
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 1.125rem;
$line-height-base: 1.5;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Heading
$headings-margin-bottom: 0;
$headings-font-weight: 400;
$headings-line-height: 1.5;

// Navbar
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-toggler-border-radius: 0;
$navbar-toggler-focus-width: 0;

$navbar-nav-link-padding-x: 1.125rem;
$navbar-light-color: $dark;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-light-disabled-color: rgba($dark, 0.3);

$nav-link-padding-y: 1rem;
$nav-link-padding-x: 1rem;
$nav-link-font-size: 1rem;
$nav-link-font-weight: 400;
$nav-link-color: $dark;
$nav-link-hover-color: $primary;

// Button
$btn-font-size-lg: 18px;
$btn-font-size: 16px;
$btn-font-size-sm: 14px;
$btn-border-width: 0;

// Accordion
$accordion-padding-x: 40px;
$accordion-padding-y: 30px;
$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;
$accordion-color: $dark;
$accordion-border-width: 1px;
$accordion-border-radius: 0;
$accordion-border-color: $grey-c2;
$accordion-button-color: $primary;
$accordion-button-active-bg: $white;
$accordion-icon-width: 14.5px;
$accordion-icon-height: 9px;
$accordion-icon-transform: rotate(0deg);
$accordion-button-focus-box-shadow: none;
$accordion-button-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.539' height='9' viewBox='0 0 14.539 9'%3E%3Cpath id='noun-down-1920917_copy' data-name='noun-down-1920917 copy' d='M0,1.946,2,0,7.269,5.124,12.534,0l2,1.946L7.269,9Z' fill='%231dbab1'/%3E%3C/svg%3E%0A");
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1.94554L2.00478 0L7.26923 5.12376L12.5337 0L14.5385 1.94554L7.26923 9L0 1.94554Z' fill='%231DBAB1'/%3E%3C/svg%3E%0A");

// FORM CONTROL
$input-font-size: 16px;
$input-padding-y: 11px;
$input-padding-x: 12px;
$input-padding-y-sm: 6px;
$input-padding-x-sm: 6px;
$input-color: $dark;
$input-box-shadow: none;
$input-border-color: $grey-db;
$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-focus-box-shadow: none;
$input-focus-color: $dark;
// $input-focus-border-color: $blue-4b;
$input-placeholder-color: $dark;

// FORM SELECT
$form-select-font-size: $input-font-size;
$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-padding-y-sm: $input-padding-y-sm;
$form-select-padding-x-sm: $input-padding-x-sm;
$form-select-color: $input-color;
$form-select-box-shadow: $input-box-shadow;
$form-select-border-color: $input-border-color;
$form-select-border-radius: $input-border-radius;
$form-select-border-radius-sm: $input-border-radius-sm;
$form-select-focus-box-shadow: $input-focus-box-shadow;
$form-select-focus-color: $input-focus-color;
// $form-select-focus-border-color: $blue-4b;
$form-select-placeholder-color: $input-placeholder-color;
$form-select-bg-position: right 15px top calc(50% + 1px);
$form-select-bg-size: 8px 5px;
$form-select-indicator: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1.08086L1.10316 0L4 2.84653L6.89684 0L8 1.08086L4 5L0 1.08086Z' fill='%230A2724'/%3E%3C/svg%3E%0A");

// FILE UPLOAD

$form-file-button-color: $white;
$form-file-button-bg: $dark;
$form-file-button-hover-bg: shade-color($form-file-button-bg, 5%);

// CHECKBOX
$form-check-input-width: 1.2em;
$form-check-input-border-radius: 4px;
$form-check-input-bg: $white;
$form-check-input-checked-bg-color: $white;
// $form-check-input-border: 1px solid $grey-c5;
// $form-check-input-focus-border: 1px solid $grey-c5;
$form-check-input-focus-box-shadow: none;
$form-check-input-checked-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12'%3E%3Cpath d='M.218 6.789a.871.871 0 00.1 1.249l4.493 3.748A.915.915 0 005.4 12h.081a.91.91 0 00.624-.32l7.685-9.1a.865.865 0 00.2-.649.88.88 0 00-.329-.6L12.26.2a.929.929 0 00-1.281.122l-5.914 7-2.378-1.975a.93.93 0 00-1.284.1z' fill='%2312c7ae'/%3E%3C/svg%3E");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Ccircle cx='8' cy='8' r='8' fill='%2312c7ae'/%3E%3C/svg%3E%0A");

// DROPDOWN
$dropdown-min-width: 14rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0;
$dropdown-spacer: 0;
$dropdown-font-size: 16px;
$dropdown-border-color: $grey-c5;
$dropdown-border-radius: 0;
$dropdown-link-color: $dark;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: rgba($primary, 0.05);
$dropdown-link-active-color: $white;
$dropdown-link-active-bg: $primary;
$dropdown-item-padding-y: 13px;
$dropdown-item-padding-x: 13px;
$caret-width: 0;

// CARD
$card-border-width: 0;
$card-border-radius: 0;
$card-bg: $white;
$card-spacer-y: 10px;
$card-spacer-x: $nav-link-padding-x;
$card-box-shadow: 0px 4px 6px rgba($black, 0.14);

// CONTAINER

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1120px,
  xxl: 1130px,
);

// BREADCRUMB

$breadcrumb-font-size: 16px;
$breadcrumb-padding-y: 17px;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding-x: 10px;
$breadcrumb-margin-bottom: 0;
$breadcrumb-divider-color: $dark;
$breadcrumb-active-color: $dark;
$breadcrumb-divider: quote(">");

// BADGE

$badge-font-size: 16px;
$badge-font-weight: $font-weight-medium;
$badge-color: $white;
$badge-padding-y: 15px;
$badge-padding-x: 20px;
$badge-border-radius: 0;

// POPOVER

$popover-font-size: 12px;
$popover-bg: $white;
$popover-max-width: 186px;
$popover-border-width: 0;
$popover-border-radius: 0;
$popover-box-shadow: 0px 4px 6px rgba($black, 0.14);

$popover-header-font-size: 16px;
$popover-header-bg: $white;
$popover-header-color: $primary;
$popover-header-padding-y: 10px;
$popover-header-padding-x: 16px;

$popover-body-color: $dark;
$popover-body-padding-y: $popover-header-padding-y;
$popover-body-padding-x: $popover-header-padding-x;

$popover-arrow-width: 21px;
$popover-arrow-height: 9px;

// MODAL

$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-backdrop-opacity: 0.2;

// SWIPER SLIDER

$themeColor: $white;
