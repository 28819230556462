@mixin firefox-scroll {
  // scrollbar-width: thin;
  // scrollbar-color: $grey-b1 $white-f6;
}
/* USED LIKE */
// @include firefox-scroll;

/* BACKGROUD IMAGE */
@mixin background($imgpath, $position: center top, $repeat: no-repeat, $size: cover) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
    size: $size;
  }
}

/* HOW TO USED
.test {
    @include background($position:auto);
}
*/

/* AFTER BEFORE */

@mixin afterbefore(
  $content: "",
  $position: absolute,
  $top: 0,
  $bottom: auto,
  $left: 0,
  $right: auto,
  $height: 100%,
  $width: 100%,
  $backgroundColor: transparent,
  $opacity: 0.5
) {
  content: $content;
  position: $position;
  top: $top;
  left: $left;
  bottom: $bottom;
  right: $right;
  height: $height;
  width: $width;
  background-color: $backgroundColor;
  opacity: $opacity;
}

/* 
 * HOW TO USED
 @include afterbefore($backgroundColor: "blue"); 
 */
