.dropdown-toggle {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1.08086L1.10316 0L4 2.84653L6.89684 0L8 1.08086L4 5L0 1.08086Z' fill='%230A2724'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center right 5px;
}

.dropdown-menu {
  li {
    &:not(:last-child) {
      .dropdown-item {
        border-bottom: 1px solid $grey-c5;
      }
    }
  }
}
