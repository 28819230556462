.heading-01 {
  font-size: 72px;
  line-height: 80px;

  @include media-breakpoint-down(lg) {
    font-size: 36px;
    line-height: 48px;
  }
}

.heading-02 {
  font-size: 36px;

  @include media-breakpoint-down(lg) {
    font-size: 30px;
  }
}

.heading-03 {
  font-size: 24px;

  @include media-breakpoint-down(lg) {
    font-size: 22px;
  }
}

.heading-04 {
  font-size: 21px;

  @include media-breakpoint-down(lg) {
    font-size: 20px;
  }
}

.heading-05 {
  font-size: 20px;
  line-height: 36px;

  @include media-breakpoint-down(lg) {
    font-size: 18px;
    line-height: 32px;
  }
}

.heading-06 {
  font-size: 18px;

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
}

.heading-07 {
  font-size: 16px;

  @include media-breakpoint-down(lg) {
    font-size: 14px;
  }
}

.heading-08 {
  font-size: 14px;

  @include media-breakpoint-down(lg) {
    font-size: 12px;
  }
}
